import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import Avatar from '@mui/material/Avatar';
import Stack from '@mui/material/Stack';
import { CirclePicker } from 'react-color';
import { useForm } from 'react-hook-form';
import { POST } from '../../utils/apis';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ShareModal from './ShareModal';
import { Divider, useTheme, Modal } from '@mui/material';
import MaterialModal from './MaterialModal';
import { useSnackbar } from 'notistack';
import { errorMessage } from '../../utils/helpers';
import DownloadCADFileModal from './DownloadCADFileModal'
import { storLocModalId, ErnestModelIds } from '../../utils/constants';

const SelectMultipleMaterials = (props) => {
  const {
    availableMaterials,
    currentConfiguration,
    configId,
    cachedConfiguration,
    materialColors,
    selectedMaterial,
    isPreview = false,
    dataImg,
    productPrice,
    StorLocNewSchema,
    setModelRefreshing,
    allConfigurationInputs,
  } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const modelId = props.modelData.modelId;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();

  const [active, setActive] = useState(0);
  const [open, setOpen] = useState(false);
  const [materialOpen, setMaterialOpen] = useState(false);
  const [modelConfiguration, setModelConfiguration] = useState(cachedConfiguration);

  const debounceTimeoutRef = useRef(null);

  useEffect(() => {
    setModelConfiguration(cachedConfiguration);
  }, [cachedConfiguration]);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const useform = useForm();

  const GeneratePdf = (event) => {
    setModelRefreshing(true);
    const formdata = new FormData();
    formdata.append('configuration', JSON.stringify(props?.configurationInputs));
    formdata.append('dataImg', props?.dataImg);
    formdata.append('productPrice', props?.productPrice);
    formdata.append('isNewSchema', StorLocNewSchema);
    formdata.append('modelId', modelId);
    axios
      .post(POST.PDF_GENERATE, formdata, {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        responseType: 'arraybuffer'
      })
      .then((res) => {
        const { data } = res;

        const blob = new Blob([data], { type: 'application/pdf' });

        const url = window.URL.createObjectURL(blob);

        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = 'quotation.pdf';

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);
        setModelRefreshing(false);
      })
      .catch((e) => {
        console.log('e', e);
        return enqueueSnackbar(errorMessage(), { variant: 'error' });
      });
  };

  const DownloadCADFile = async () => {
    setModelRefreshing(true);

    try {
        const formData = {
            configuration: props?.currentConfiguration,
            modelId: modelId
        };

        const response = await axios.post(POST.DOWNLOAD_CAD, formData, { responseType: 'blob' });
        const { data } = response;

        const blob = new Blob([data]);
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.style.display = 'none';
        a.href = url;
        a.download = `${modelId}.step`;

        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);

        window.URL.revokeObjectURL(url);

    } catch (e) {
        console.log('Error downloading CAD file:', e);
        enqueueSnackbar(errorMessage(), { variant: 'error' });
    } finally {
        setModelRefreshing(false);
    }
};


  const ResetForm = () => {
    useform.reset({
      username: cachedConfiguration.username || '',
      email: cachedConfiguration.email || '',
      extraNotes: cachedConfiguration.extraNotes || ''
    });
  };

  const ValidateInputs = () => {
    if (Object.keys(useform.formState.errors).length > 0) {
      Object.values(useform.formState.errors).map((msg) => {
        errorMessage(msg.message);
      });
    }
  };

  //multiple material names and colors for stor-loc, ideally we store this in the database "storloc" has [color1, color2, color3, etc.]
  //const colorNames = ['gloss_black', 'cool_white', 'light_gray', 'charcoal_gray', 'case_red', 'red', 'pumpkin_orange', 'AG_yellow', 'AG_green', 'shade_green', 'blue_ribbon', 'alpine_blue']
  const colorOptions = {
    'Gloss Black': '#000004',
    'Cool White': '#EBECE7',
    'Light Gray': '#A2A4A6',
    'Charcoal Gray': '#303039',
    'Case Red': '#820000',
    Red: '#A30003',
    Pumpkin: '#D52A00',
    'AG Yellow': '#EAC400',
    'AG Green': '#0B6422',
    'Shade Green': '#00352D',
    'Dark Blue': '#002885',
    'Alpine Blue': '#6B99C8'
  };

  const handleCircleChange = (i, e) => {
    let tempMaterialColors = materialColors;
    tempMaterialColors[i] = e?.hex.slice(1).toUpperCase();
    props?.setMaterialColors([...tempMaterialColors]);
    if (debounceTimeoutRef.current) {
      clearTimeout(debounceTimeoutRef.current);
    }
    debounceTimeoutRef.current = setTimeout(() => {
      props?.updateModel();
    }, 1600);
  };

  const materialSelectors = [];

  for (let i = 0; i < availableMaterials[active]?.color_selector_labels?.length; i++) {
    materialSelectors.push(
      <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '15px' }} key={i}>
        <label className="configParamLabel" style={{ width: 'auto' }}>
          {availableMaterials[active]?.color_selector_labels[i]}
        </label>
        <CirclePicker
          width="75%"
          circleSize={20}
          colors={Object.values(colorOptions)}
          color={materialColors[i] || '#FFFFFF'}
          onChange={(e) => {
            handleCircleChange(i, e);
          }}
          onSwatchHover={(colorRes, e) => {
            const colorLabels = Object.keys(colorOptions);
            const colorCodes = Object.values(colorOptions);
            const selectedIndex = colorCodes.findIndex((color) => {
              return color.toLocaleLowerCase() === colorRes.hex.toLocaleLowerCase();
            });
            e.target.title = colorLabels[selectedIndex];
          }}
        />
      </div>
    );
  }

  return (
    <Box xs={12} position="relative">
      {materialSelectors}
      {availableMaterials[active]?.color_selector_labels?.length > 0 && <Divider sx={{ my: '15px' }} />}
      <Stack direction="row" alignItems="center" justifyContent="center" spacing={2}>
        <Avatar
          alt="Spokbee material swatch"
          src={`${selectedMaterial?.swatch}`}
          sx={{ width: 56, height: 56, mt: 1, mb: 1, border: `2px solid ${theme.palette.custom.gray}` }}
        />
        <Box>
          <Typography paragraph={true} fontWeight="bold" sx={{ pr: '30px' }}>
            {selectedMaterial?.title}
          </Typography>
          <Typography paragraph={true} lineHeight={1.4} fontSize={14}>
            {selectedMaterial?.description}
          </Typography>
        </Box>
      </Stack>

      <Box display="flex" justifyContent="space-between" flexDirection="column" gap={2}>
        {availableMaterials?.length > 1 ? (
          <Button variant="contained" className="Button" onClick={() => setMaterialOpen(true)}>
            Update Material
          </Button>
        ) : null}
        <Button
          variant="contained"
          className="Button mt-2"
          onClick={(e) => {
            handleOpen();
            ResetForm(e);
          }}
          disabled={isPreview || props?.modelRefreshing}
        >
          Save Configuration
        </Button>
        {[storLocModalId, ...ErnestModelIds].includes(modelId) && (
          <Button
            variant="contained"
            className="Button mt-2"
            onClick={(e) => {
              GeneratePdf(e);
            }}
            disabled={isPreview || props?.modelRefreshing || props?.productPrice == ''}
          >
            Download Quote
          </Button>
        )}

        <DownloadCADFileModal
          downloadCADFile={DownloadCADFile}
          isPreview={isPreview}
          modelRefreshing={props?.modelRefreshing}
          productPrice={productPrice}
          modelId={modelId}
          configration={currentConfiguration}
        />

        {/* {props?.modelData?.isDownloadable && props?.modelData?.isDownloadable == true && (
          <Button
            variant="contained"
            className="Button mt-2"
            onClick={(e) => {
              DownloadCADFile(e);
            }}
            disabled={isPreview || props?.modelRefreshing || props?.productPrice == ''}
          >
            Download CAD File
          </Button>
        )} */}

        {props?.modelData?.isDownloadable && props?.modelData?.isDownloadable == true && (
          <Button
            variant="contained"
            className="Button mt-2"
            onClick={(e) => {
              DownloadCADFile(e);
            }}
            disabled={isPreview || props?.modelRefreshing || props?.productPrice == ''}
          >
            Download CAD File
          </Button>
        )}
      </Box>

      <MaterialModal
        open={materialOpen}
        handleClose={() => setMaterialOpen(false)}
        availableMaterials={availableMaterials}
        setActive={setActive}
        active={active}
        setSelectedMaterial={props?.setSelectedMaterial}
        colorOptions={colorOptions}
      />
      {isPreview == false && (
        <ShareModal
          useform={useform}
          ValidateInputs={ValidateInputs}
          open={open}
          handleOpen={handleOpen}
          handleClose={handleClose}
          modelConfiguration={modelConfiguration}
          setModelConfiguration={setModelConfiguration}
          modelId={modelId}
          currentConfiguration={currentConfiguration}
          configId={configId}
          dataImg={dataImg}
          productPrice={productPrice}
          StorLocNewSchema={StorLocNewSchema}
          allConfigurationInputs={allConfigurationInputs}
        />
      )}
    </Box>
  );
};

SelectMultipleMaterials.propTypes = {
  // modelData: PropTypes.object.isRequired,
  availableMaterials: PropTypes.array
  // updateMaterial: PropTypes.func.isRequired,
};

export default SelectMultipleMaterials;
