import React, { useCallback, useEffect, useRef, useState, memo } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';

// Material UI imports
import CheckIcon from '@mui/icons-material/Check';
import { Box, CircularProgress, Container, Grid, Paper, Stack, Typography, useTheme } from '@mui/material';
import Button from '@mui/material/Button';

const { v4: uuidv4 } = require('uuid');

// Components Import
import { ProductDescription, SwipeableTextMobileStepper } from '../../Components';

// Custom Hooks
import useIframe from '../../Hooks/useIframe';

// ConfiguratorComponent is responsible to generate the dynamic Configurator Inputs for different Models
import Configurator from '../../Components/Editor/Configurator';

// three.js functions
import { initThreeJsElements } from '../../Components/Editor/Canvas';
import { Scene } from 'three';

// for API Import
import { GET, POST } from '../../utils/apis';
import { allPaths, storLocModalId, mezcoModelIds, albaRacingModelId, Chromatic3DProds, EARNESTCHECKDEPS, LIBERTY_PEN_GUN, SkyHookModelID } from '../../utils/constants';

// helper Functions
import { errorMessage, getConfigParamsSchema, getConfiguratorUnit } from '../../utils/helpers';

import BoundingBox from '../../Components/Editor/BoundingBox';
import { SectionTitle, SectionWrapper } from '../../Components/Common';
import { useSnackbar } from 'notistack';
import { useSelector } from 'react-redux';
import MinOrderBanner from '../../Components/Editor/MinOrderBanner';
import SoldBy from '../../Components/Editor/SoldBy';
import Login from '../Login/Login';

const Frame = memo((props) => {
  const { previousPath } = props;
  const location = useLocation();
  const navigate = useNavigate();
  const IsIframe = useIframe();
  const modelId = new URLSearchParams(location?.search).get('id') || '';
  const storeId = new URLSearchParams(location?.search).get('storeId') || '';
  const configId = new URLSearchParams(location.search).get('configId') || '';
  const clearCache = new URLSearchParams(location.search).get('cl-cache') || false;
  const theme = useTheme();
  const { enqueueSnackbar } = useSnackbar();
  const { admin: loggedinUser } = useSelector((state) => state.adminAuthReducer);

  const scene = new Scene();
  const canvas = useRef(null); // This are Ref which holds the current values of the state

  // This state holds the value of the response of the onshape api data
  const [loading, setLoading] = useState(true); // BOOLEAN STATE FOR INITIAL LOAD
  const [modelRefreshing, setModelRefreshing] = useState(true); // BOOLEAN STATE FOR DETACT MODEL REFRESHING
  const [pricingRefreshing, setPricingRefreshing] = useState(true); // BOOLEAN STATE FOR DETACT MODEL REFRESHING
  const [modelData, setModelData] = useState({});
  const [productPrice, setProductPrice] = useState('');
  const [selectedMaterial, setSelectedMaterial] = useState({});
  const [availableMaterials, setAvailableMaterials] = useState([]);
  const [productImages, setProductImages] = useState([]);
  const [currentConfiguration, setCurrentConfiguration] = useState({});
  const [boundingBoxDimentions, setBoundingBoxDimentions] = useState({}); // USED ONLY FOR STOR-LOC PRODUCT
  const [materialColors, setMaterialColors] = useState([]); // USED ONLY FOR STOR-LOC PRODUCT
  // Configurator Holds the value of the onshape API configurator default values and other all information related to the configuration
  const [configurator, setConfigurator] = useState([]);
  const [configurationInputs, setConfigurationInputs] = useState({});
  const [allConfigurationInputs, setAllConfigurationInputs] = useState({});
  const [dataImg, setDataImg] = useState();
  const [loadedMaterial, setLoadedMaterial] = useState({});
  const [yintercept, setYintercept] = useState(0);
  const [slope, setSlope] = useState(0);
  const [margin, setMargin] = useState(0);
  const [loadedConfiguration, setLoadedConfiguration] = useState({});
  const [loadedColors, setLoadedColors] = useState([]);
  const [noConfiguration, setNoConfiguration] = useState(false);
  const [configurationUniqueId, setConfigurationUniqueId] = useState(null);
  // new schema state
  const [StorLocNewSchema, setStorLocNewSchema] = useState(false);
  const [productDimensions, setProductDimensions] = useState(null);
  const [lookupJSON, setLookupJSON] = useState({});
  const [parameterSchema, setParameterSchema] = useState([]);
  const [savedConfigurationDate, setSavedConfigurationDate] = useState(null);
  const [isPrivate, setIsPrivate] = useState(false);
  const [showLogin, setShowLogin] = useState(false);
  // This state holds the value of actions during Add To Cart
  const [showAlert, setShowAlert] = useState(false);
  const r = useSelector((state) => state.adminAuthReducer);
  // FETCH MODEL DETAILS ON INITIAL LOAD
  useEffect(() => {
    // This UseEffect Fetch the model and GLTF for first initial rendering to the DOM BY The modelId

    if (isPrivate) {
      if (!loggedinUser) {
        setShowLogin(true);
      }
    }
  }, [isPrivate, loggedinUser]);

  // REFRESH MODEL SPINNER ICONS
  useEffect(() => {
    if (
      Object.is(loadedMaterial, selectedMaterial) &&
      Object.is(loadedConfiguration, currentConfiguration) &&
      JSON.stringify(loadedColors) == JSON.stringify(materialColors)
    ) {
      setModelRefreshing(false);
      // generateImage();
    }
  }, [loadedMaterial, loadedConfiguration, loadedColors]);

  useEffect(() => {
    const isMaterialSelected = Object.keys(selectedMaterial).length > 0;
    const isConfigurationSelected = Object.keys(configurationInputs).length > 0;

    if ((isConfigurationSelected || noConfiguration) && isMaterialSelected) {
      const handler = setTimeout(() => {
        updateModel();
      }, 2000);
      return () => {
        clearTimeout(handler);
      };
    }
  }, [configurationInputs, selectedMaterial]);

  useEffect(() => {
    // This UseEffect Fetch the model and GLTF for first initial rendering to the DOM BY The modelId
    getModel();

    if (location?.pathname === '/store' && modelId !== storLocModalId) {
      return navigate(`${allPaths?.EDITOR}?id=${modelId}`);
    }
  }, []);

  const getModel = useCallback(async () => {
    try {
      let url = '';

      if (configId === '') {
        url = `${GET.EDITOR_BY_ID}/${modelId}`;
      } else {
        url = `${GET.EDITOR_BY_ID}/${modelId}/${configId}`;
      }
      url = `${url}?cl-cache=${clearCache}`;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${loggedinUser?.accessToken}ss`
        }
      });

      const { data } = response;
      if (!data?.published) {
        navigate('/page404');
        return;
      }

      setLoading(false);
      setSelectedMaterial(data?.defaultMaterial);
      setModelData({ ...data });
      setAvailableMaterials(data?.available_materials);

      setIsPrivate(data?.isPrivate);

      if (data?.isPrivate) {
        if (!loggedinUser) {
          setShowLogin(true);
          return;
        } else if (
          loggedinUser?.userRole == 2 &&
          (loggedinUser?.permissionGroup?.includes('privateStorefront') == false ||
            loggedinUser?.customerProducts?.includes(modelId) == false)
        ) {
          setShowLogin(true);
          return;
        } else if (loggedinUser?.userRole == 3) {
          const privateStorefrontsAccess = loggedinUser?.privateStorefronts
            ?.filter((item) => item.canView == true)
            .map((item) => item?.baseFigureId);

          if (privateStorefrontsAccess.includes(modelId) == false) {
            enqueueSnackbar('You have not permission of this storefront', { variant: 'warning' });
            navigate(allPaths.HOME);
            return;
          }
        }
      }

      if (!data?.priceFromOnshape) {
        setMargin(data.margin);
        setYintercept(data.pricing.yintercept || (Array.isArray(data?.pricing) ? data?.pricing[0]?.yintercept : 0));
        setSlope(data.pricing.slope || (Array.isArray(data?.pricing) ? data?.pricing[0]?.slope : 0));
      }

      const images = data?.ProductImages?.map((x) => ({
        label: uuidv4(),
        imgPath: x?.url,
        type: x?.type,
        isHome: x?.isHome
      }));
      setProductImages(images);

      setConfigurator(data?.configuration);

      if (!(modelId === storLocModalId || Chromatic3DProds.includes(modelId) || mezcoModelIds.includes(modelId))) {
        setProductPrice(data?.defaultMaterial?.price);
      }

      if (data?.configuration?.configurationParameters?.length === 0) {
        setNoConfiguration(true);
      }

      // PROCESS THE CONFIGURATION INPUT VALUES
      // check if configuration is saved in cache
      let defaultConfigurationInputs = {};
      if (
        data?.cachedConfiguration &&
        data?.cachedConfiguration?.configuration &&
        Object.keys(data?.cachedConfiguration?.configuration).length > 0
      ) {
        if (data?.hasLookupfields) {
          const paramName = data?.featureScriptConf?.configurationParameters[0]?.message?.parameterId;
          const cachedData = data?.cachedConfiguration?.configuration.find((field) => field.identifier === paramName);

          defaultConfigurationInputs = JSON.parse(cachedData.value);
        } else if (modelId === storLocModalId) {
          const cachedColors = [];
          defaultConfigurationInputs['JSON_STRING'] = data?.cachedConfiguration?.configuration[0].value;
          const { FColor, DColor } = JSON.parse(defaultConfigurationInputs['JSON_STRING'] = data?.cachedConfiguration?.configuration[0].value);
          if (FColor) cachedColors.push(FColor);
          if (DColor) cachedColors.push(DColor);
          setMaterialColors(cachedColors);
          setStorLocNewSchema(true);
        } else {
          data?.cachedConfiguration?.configuration.forEach((field) => {
            defaultConfigurationInputs[field.identifier] = field.value;
          });
        }
      } else if (modelId === storLocModalId) {
        // IF STORLOC MODEL
        defaultConfigurationInputs['JSON_STRING'] = data?.configuration?.configurationParameters[0]?.message.defaultValue;

        const parsedJsonString = JSON.parse(defaultConfigurationInputs['JSON_STRING']);
        const initialColors = [];

        const { Drawers, FColor, DColor } = parsedJsonString;

        if (Drawers[0]?.hasOwnProperty('arrayPointIndex')) {
          if (FColor) initialColors.push(FColor.Color);
          if (DColor) initialColors.push(DColor.Color);
        } else {
          if (FColor) initialColors.push(FColor);
          if (DColor) initialColors.push(DColor);
        }

        setMaterialColors(initialColors);
        setStorLocNewSchema(!Drawers[0]?.hasOwnProperty('arrayPointIndex'));
      } else if (data?.hasLookupfields) {
        // IF HAS LOOKUP FIELDS

        defaultConfigurationInputs = JSON.parse(data?.featureScriptConf?.configurationParameters[0]?.message?.defaultValue);
        // data?.configuration?.configurationParameters.forEach((item) => {
        //   defaultConfigurationInputs[item?.message?.parameterId] = item?.message?.rangeAndDefault?.message.defaultValue
        // })
      } else {
        // REST ALL STOREFRONTS
        data?.configuration?.configurationParameters?.forEach((confParam) => {
          if (confParam.type == 1826) {
            return (defaultConfigurationInputs[confParam?.message?.parameterId] =
              confParam.message.rangeAndDefault?.message?.defaultValue || confParam.message.rangeAndDefault?.message?.minValue || 0);
          } else if (confParam.type == 2550) {
            return (defaultConfigurationInputs[confParam?.message?.parameterId] = confParam.message.defaultValue == true);
          } else if (confParam.type == 105) {
            return (defaultConfigurationInputs[confParam?.message?.parameterId] = confParam.message.options[0].message.option);
          } else if (confParam.type == 872) {
            return (defaultConfigurationInputs[confParam?.message?.parameterId] = confParam.message.defaultValue);
          }
        });
      }
      setConfigurationInputs(defaultConfigurationInputs || {});

      //         if (modelId === storLocModalId) {
      //           if (Object.keys(data?.cachedConfiguration).length > 0) {
      //             defaultConfigurationInputs['JSON_STRING'] = data?.cachedConfiguration?.configuration[0].value;
      //           } else {
      //             deafaultConfigurationInputs['JSON_STRING'] = data?.configuration?.configurationParameters[0]?.message.defaultValue;
      //           }

      //           const parsedJsonString = JSON.parse(deafaultConfigurationInputs['JSON_STRING']);
      //           const initialColors = [];

      //           const { Drawers, FColor, DColor } = parsedJsonString;

      //           if (Drawers[0]?.hasOwnProperty('arrayPointIndex')) {
      //             if (FColor) initialColors.push(FColor.Color);
      //             if (DColor) initialColors.push(DColor.Color);
      //           } else {
      //             if (FColor) initialColors.push(FColor);
      //             if (DColor) initialColors.push(DColor);
      //           }

      //           setMaterialColors(initialColors);
      //           setStorLocNewSchema(!Drawers[0]?.hasOwnProperty('arrayPointIndex'))
      //         }

      //         if (mezcoModelIds.includes(modelId)) {
      //           if (data?.cachedConfiguration && data?.cachedConfiguration?.configuration && Object.keys(data?.cachedConfiguration?.configuration).length > 0) {
      //             data?.cachedConfiguration?.configuration.forEach((field) => {
      //               deafaultConfigurationInputs[field.identifier] = field.value;
      //             });
      //           }
      //         }

      // check if configuration is saved in cache
      //         if (
      //           data?.cachedConfiguration &&
      //           data?.cachedConfiguration?.configuration &&
      //           Object.keys(data?.cachedConfiguration?.configuration).length > 0
      //         ) {
      //           data?.cachedConfiguration?.configuration.forEach((field) => {
      //             deafaultConfigurationInputs[field.identifier] = field.value;
      //           });
      //         }

      //         let deafaultConfiguration = data?.configuration?.currentConfiguration?.map((confParam) => {
      //           const paramId = confParam?.message?.parameterId;

      //           if (
      //             deafaultConfigurationInputs[paramId] &&
      //             deafaultConfigurationInputs[paramId].toString()?.endsWith(getConfiguratorUnit(confParam?.message?.units)) == true
      //           ) {
      //             deafaultConfigurationInputs[paramId] = deafaultConfigurationInputs[paramId]
      //               .toString()
      //               .replace(getConfiguratorUnit(confParam?.message?.units), '');
      //             return `${paramId}=${deafaultConfigurationInputs[paramId]}`;
      //           } else {
      //             return `${paramId}=${deafaultConfigurationInputs[paramId]}${modelId === storLocModalId ? '' : getConfiguratorUnit(confParam?.message?.units)
      //               }`;
      //           }
      //         });

      //         setConfigurationInputs(deafaultConfigurationInputs || {});
      //         setCurrentConfiguration(deafaultConfiguration.join('&'));
      //       })
      //       .catch((e) => {
      //         console.log(e);
      //         enqueueSnackbar('Oops No Data Found!', { variant: 'warning' });
      //         setLoading(false);

      // ADD UNITS TO CONIGURATION SCHEMA
      const defaultConfiguration = data?.configuration?.currentConfiguration?.map((confParam) => {
        const paramId = confParam?.message?.parameterId;
        let value = defaultConfigurationInputs[paramId];

        if (value && value.toString()?.endsWith(getConfiguratorUnit(confParam?.message?.units))) {
          value = value.toString().replace(getConfiguratorUnit(confParam?.message?.units), '');
        } else if (modelId !== storLocModalId) {
          value += getConfiguratorUnit(confParam?.message?.units);
        }

        return `${paramId}=${value}`;
      });

      setCurrentConfiguration(defaultConfiguration.join('&'));
    } catch (error) {
      if (error?.response?.status && error?.response?.status == 403) {
        setShowLogin(true);
        setModelData(error?.response?.data?.data);
        enqueueSnackbar(error?.response?.data?.message, { variant: 'warning' });
      } else {
        enqueueSnackbar('Oops! No Data Found!', { variant: 'warning' });
      }
      setLoading(false);
    }
  }, []);

  // updateModel will update the model with the new states and update the model configuration only and pass material data to the updatePricingAndMaterials

  const updateModel = useCallback(async () => {
    setModelRefreshing(true);
    setPricingRefreshing(true);
    let loadedColors = [];
    // This Condition is only for the Stor-Loc
    if (modelData?.modelId === storLocModalId && configurationInputs?.JSON_STRING) {
      const jsonFormData = JSON.parse(configurationInputs['JSON_STRING']);

      const { Drawers, FColor, DColor } = jsonFormData;

      if (!StorLocNewSchema) {
        if (FColor) {
          FColor.Color = materialColors[0];
          loadedColors.push(FColor.Color);
        }
        if (DColor) {
          DColor.Color = materialColors[1];
          loadedColors.push(DColor.Color);
        }
      } else {
        if (FColor) {
          jsonFormData.FColor = materialColors[0];
          loadedColors.push(FColor);
        }
        if (DColor) {
          jsonFormData.DColor = materialColors[1];
          loadedColors.push(DColor);
        }
      }

      configurationInputs.JSON_STRING = JSON.stringify(jsonFormData);
    }
    // queryObj Holds temp value for update the material and configuration
    let queryObj = {};

    const configurationParametersUnits = configurator?.currentConfiguration?.map((confParam) => {
      return {
        parameterId: confParam?.message?.parameterId,
        unit: confParam?.message?.units ? confParam?.message?.units : ''
      };
    });

    const configuration = Object.entries(configurationInputs)
      .map(([key, value]) => {
        if (modelData?.hasLookupfields) {
          // FOR LOOKUP NO NEED OF UNITS
          configurationInputs[key] = value ? value : '';
          return;
        } else {
          const unitString = configurationParametersUnits?.find((confparam) => confparam.parameterId === key);
          const valueUnit = getConfiguratorUnit(unitString?.unit);
          return key + '=' + (value ? value : '') + valueUnit + ';';
        }
      })
      .join('')
      .slice(0, -1);

    if (modelData?.hasLookupfields) {
      let updatedConfiguration = JSON.parse(JSON.stringify(configurationInputs));
      Object.keys(updatedConfiguration).forEach((inputName) => {
        if (updatedConfiguration[inputName] === 'LPBLK') {
          updatedConfiguration[inputName] = '';
        }
      });
      setAllConfigurationInputs(updatedConfiguration);
      if (modelData?.modelId === '663c5ed91969a3312eeb96b5') {
        let conf = updatedConfiguration;
        const removeFields = [
          'Post_Processing__Top_hole',
          'Post_Processing__Side_hole',
          'Pin_Lock_Hole',
          'Dog_Point',
          'List_QAoAroAoJ5JICg',
          'Standard'
        ];
        const numberVals = [
          'ThruDiameter',
          'WireDiameter',
          'PinDiameter',
          'PinOffset',
          'DogPointDiameter',
          'DogPointLength',
          'PartialThread',
          'Diameter',
          'Length'
        ];
        Object.keys(EARNESTCHECKDEPS).forEach((field) => {
          if (conf[EARNESTCHECKDEPS[field]] == '' || conf[EARNESTCHECKDEPS[field]] == false) {
            conf[field] = 0;
          }
        });

        // removeFields.forEach((key) => {
        //   delete conf[key];
        // });

        numberVals.forEach((key) => {
          conf[key] = conf[key] && conf[key] != null && conf[key] != '' ? conf[key] * 1 : 0;
        });

        conf['flange'] = conf['flange'] == 'false' ? false : true;

        queryObj['configuration'] = `${modelData?.featureScriptConf?.configurationParameters[0]?.message?.parameterId}=${JSON.stringify(
          conf
        )}`;
      } else if (mezcoModelIds.includes(modelId)) {
        updatedConfiguration['thickness'] = updatedConfiguration['thickness'] * 1;

        queryObj['configuration'] = `${modelData?.featureScriptConf?.configurationParameters[0]?.message?.parameterId}=${JSON.stringify(
          updatedConfiguration
        )}`;
      } else {
        queryObj['configuration'] = `${modelData?.featureScriptConf?.configurationParameters[0]?.message?.parameterId}=${JSON.stringify(
          updatedConfiguration
        )}`;
      }
    } else {
      queryObj['configuration'] = configuration;
    }

    queryObj['id'] = modelId;
    queryObj['material'] = selectedMaterial;
    setCurrentConfiguration(queryObj?.configuration);

    updatePricingAndMaterials(queryObj);

    await axios
      .post(POST.MODEL_CONFIGURATION, queryObj)
      .then(async (data1) => {
        const data = data1?.data;
        const { loadGltf } = initThreeJsElements(canvas, scene, modelId, setDataImg);
        loadGltf(data?.gltf, data?.material, modelId, setDataImg);
        setConfigurationUniqueId(uuidv4());

        setLoadedConfiguration(queryObj.configuration);
        setLoadedMaterial(queryObj.material);
        setLoadedColors(loadedColors);
        setModelRefreshing(false);
        setPricingRefreshing(false);
      })
      .catch((e) => {
        console.log('e', e);
        if (previousPath === location.pathname) {
          enqueueSnackbar(errorMessage(), { variant: 'error' });
        }
      });

    // BOUNING BOX DIMENTIONS VALUES FOR STOR-LOC PRODUCT ONLY
    if (modelData?.modelId === storLocModalId) {
      await axios
        .post(POST.BOUNDING_BOX, queryObj)
        .then((res) => {
          const { data } = res;
          setBoundingBoxDimentions(data.dimensions);
        })
        .catch((e) => {
          console.log('e', e);
        });
    }

    // Fetch Parts details of model for dynamic pricing only "Chromatic 3D" Products
    if (Chromatic3DProds.includes(modelId) || modelId === storLocModalId) {
      getModelParts(modelId, configuration);
    } else if (modelData?.hasLookupfields || modelId == SkyHookModelID) {
      getModelParts(modelId, queryObj['configuration']);
    }

  });

  // updatePricingAndMaterials update the material from the new material data from the queryObj value

  const updatePricingAndMaterials = useCallback(async (queryObj) => {
    await axios
      .post(POST.UPDATE_MATERIALS, queryObj)
      .then((res) => {
        const { data } = res;
        if (modelData?.priceFromOnshape) {
          // DO not change the update material after this request because it will the create the issue and chnaged to the default value for material
          // We already updated in updateModel function
        } else {
          let stringBasePriceOfProduct = data?.price?.replace('$', '');
          if (stringBasePriceOfProduct == 0) {
            stringBasePriceOfProduct = productPrice?.replace('$', '') || 10;
          }
          const basePrice = parseFloat(stringBasePriceOfProduct);

          let increaseAmount = basePrice * (margin / 100);
          increaseAmount = parseFloat(increaseAmount.toFixed(2));

          // From the y = mx + c ,New price (y) = Slope * Current price + Y-intercept + Increase
          let newPrice;
          if (slope && yintercept) {
            newPrice = parseFloat(slope) * basePrice + parseFloat(yintercept) + increaseAmount;
          } else {
            newPrice = basePrice + increaseAmount;
            // <<<<<<< SCS-2
          }
          const FinalAmount = `$` + parseFloat(newPrice.toFixed(2));
          setProductPrice(FinalAmount);
          // setModelRefreshing(false);
          // =======
          //           }
          //           const FinalAmount = `$` + parseFloat(newPrice.toFixed(2));
          //           if (!isNaN(Number(newPrice))) {
          //             setProductPrice(FinalAmount);
          //           }
          // >>>>>>> staging-new
          // setPricingRefreshing(false);
          setAvailableMaterials([...(data?.availableMaterialsResult || [])]);
        }
      })
      .catch((e) => {
        console.log('e', e);

        if (previousPath === location.pathname) {
          return enqueueSnackbar(errorMessage(), { variant: 'error' });
        }
      });
  });

  const getModelParts = useCallback(async (modelId, configuration) => {
    await axios
      .get(`${GET.MODEL_PARTS}`, {
        params: {
          configuration: configuration,
          id: modelId
        }
      })
      .then((res) => {
        const { data } = res;
        if (modelId === storLocModalId) {
          // Right now we are getting price inside the descripiton and name both for StorLoc
          // const { description } = data.find(item => item.description !== null); Old Logic For Discription

          const { name } = data[0];
          const { price } = JSON.parse(name.replace(/(\w+)\s*:/g, '"$1":'));

          if (name !== null) {
            setProductPrice(`$${parseFloat(price).toFixed(2)}`);
          }
        } else if (mezcoModelIds.includes(modelId) || SkyHookModelID) {
          let parsedData;
          data.forEach((item) => {
            if (item.name.startsWith('{')) {
              parsedData = JSON.parse(item?.name?.replace(/(\w+)\s*:/g, '"$1":'));
            }
          });

          const { price, bounds_mm, mass_kg } = parsedData || {};

          setProductPrice(!isNaN(Number(price)) ? `$${parseFloat(price).toFixed(2)}` : '$0');
          if (Array.isArray(bounds_mm) && bounds_mm.length >= 3) {
            setProductDimensions({
              length: bounds_mm[0],
              width: bounds_mm[1],
              height: bounds_mm[2],
              mass_kg: mass_kg ?? null
            });
          }
        } else {
          if (data[0]?.name) {
            const partPrice = data[0]?.name;
            if (!isNaN(Number(partPrice))) {
              setProductPrice(`$${parseFloat(partPrice).toFixed(2)}`);
            } else {
              setProductPrice(`$0`);
            }
          }
        }
        setPricingRefreshing(false);
        setModelRefreshing(false);
      })
      .catch((error) => {
        console.log('getModelParts ~ error:', error);
        enqueueSnackbar('Oops No Data Found!', { variant: 'warning' });
        setLoading(false);
      });
  });

  // FETCH JSON SCHEMA FOR MODELS CREAETD WITH FEATURESCRIPT
  const fetchJson = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_API_BASEURL}/api/JSON/${modelData?.jsonFileName}`);
      if (!response.ok) {
        throw new Error(`Error fetching JSON: ${response.statusText}`);
      }
      const data = await response.json();
      setLookupJSON(data);
      const paramSchemaJSON = getConfigParamsSchema(data);
      setParameterSchema(paramSchemaJSON);
    } catch (error) {
      console.error('Error fetching JSON:', error.message);
    }
  };

  useEffect(() => {
    if (modelData?.jsonFileName) {
      fetchJson();
    }
  }, [modelData]);

  return (
    <>
      {(modelData?.minorderval > 0 || modelData?.setupCharge > 0) && <MinOrderBanner modelData={modelData} />}
      <Container>
        {loading ? (
          <div className="loader-center">
            <CircularProgress />
          </div>
        ) : (
          <Box
            sx={{
              textAlign: 'left',
              marginTop: '40px',
              paddingBottom: '50px',
              ...(modelId == LIBERTY_PEN_GUN
                ? { paddingX: '12%' }
                : { paddingX: '10%' })
            }}
          >
            {showLogin == true && (
              <Box>
                <Login
                  modelName={modelData?.modelName}
                  isPrivateStorefront={modelData?.isPrivate == true ? true : false}
                  sellerStoreName={modelData?.seller?.storeName}
                />
              </Box>
            )}
            {showLogin == false && (
              <Grid container spacing={4} rowSpacing={2} direction="column">
                <Grid container item spacing={4} rowSpacing={2} alignItems="flex-end">
                  <Grid item xs={12} sm={9} md={8} float="left">
                    <Stack direction="row" spacing={2}>
                      {modelData?.seller && modelData?.seller?.storeLogo && modelData?.seller?.storeLogo?.path && (
                        <Box component="img" src={modelData?.seller?.storeLogo?.path} alt="Spokbee" sx={{ maxHeight: '100px' }} />
                      )}
                      <SectionTitle>{modelData?.modelName}</SectionTitle>
                    </Stack>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    sm={3}
                    md={4}
                    sx={{
                      paddingTop: {
                        xs: '0px !important',
                        sm: '32px !important'
                      }
                    }}
                  >
                  </Grid>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  md={8}
                  lg={8}
                  xl={8}
                  sx={{
                    paddingTop: {
                      xs: '0px !important',
                      sm: '16px !important'
                    }
                  }}
                >
                  <div style={{ position: 'relative' }}>
                    {modelRefreshing || pricingRefreshing ? (<>
                      <CircularProgress
                        style={{
                          color: 'blue',
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          margin: '2px',
                          zIndex: 9
                        }}
                      ></CircularProgress></>
                    ) : (
                      <CheckIcon
                        fontSize="large"
                        style={{
                          color: 'green',
                          position: 'absolute',
                          top: '0',
                          right: '0',
                          margin: '2px',
                          zIndex: 9
                        }}
                      ></CheckIcon>
                    )}
                    <Paper
                      elevation={2}
                      id="canvas-wrapper"
                      sx={{
                        // maxWidth: ,
                        position: 'relative',
                        borderRadius: '10px',
                        border: `1px solid ${theme.palette.custom.gray}`,
                        overflow: 'hidden'
                      }}
                    >
                      <div ref={canvas} />
                    </Paper>
                    {/* <Grid container={true}>
                      {Object.keys(boundingBoxDimentions).length > 0 && (
                        <Grid item xs={12}>
                          <BoundingBox
                            dimensions={boundingBoxDimentions}
                            StorLocNewSchema={StorLocNewSchema}
                            jsonData={JSON.parse(configurationInputs.JSON_STRING)}
                          />
                        </Grid>
                      )}
                    </Grid> */}
                  </div>
                </Grid>
                <Configurator
                  {...props}
                  updateModel={updateModel}
                  materialColors={materialColors}
                  setMaterialColors={setMaterialColors}
                  configurator={configurator}
                  setConfigurator={setConfigurator}
                  configurationInputs={configurationInputs}
                  setConfigurationInputs={setConfigurationInputs}
                  modelRefreshing={modelRefreshing}
                  setModelRefreshing={setModelRefreshing}
                  modelData={modelData}
                  productPrice={productPrice}
                  availableMaterials={availableMaterials}
                  currentConfiguration={currentConfiguration}
                  setCurrentConfiguration={setCurrentConfiguration}
                  cachedConfiguration={modelData?.cachedConfiguration || []}
                  configId={configId}
                  selectedMaterial={selectedMaterial}
                  setSelectedMaterial={setSelectedMaterial}
                  dataImg={dataImg}
                  lookupJSON={lookupJSON}
                  parameterSchema={parameterSchema}
                  setParameterSchema={setParameterSchema}
                  StorLocNewSchema={StorLocNewSchema}
                  allConfigurationInputs={allConfigurationInputs}
                  IsIframe={IsIframe}
                />
              </Grid>
            )}
          </Box>
        )}
      </Container>
    </>
  );
});

export default Frame;
