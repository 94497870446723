import HomeOutlined from '@ant-design/icons/HomeOutlined';
import allPaths from '../Config/paths';
import global from '../Config/global';

let Chromatic3DProds = [];
const bgColor = `#0adc00`;
const storLocModalId = '63b4cc4f65be5d2b79774084';
const albaRacingModelId = '660239aaef09dc00151085db';
const SkyHookModelID = '66dfc8b71c6a220048e07e93'
const LIBERTY_PEN_GUN = '66a140c95b966d0015b9c74c'
const ErnestModelIds = ['663c5ed91969a3312eeb96b5']

let mezcoModelIds = [];
// const mezcoModelIds= [
//   '6626c5aa5756571fc16015ec',
//   '66275b535756571fc16015f0',
//   '66276ff45756571fc16015f3',
//   '662797135756571fc16015f9',
//   '6627987b5756571fc16015fd'
// ];
if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  mezcoModelIds = [
    '65fc9b784c3e5f001596b34c',
    '65fc9b484c3e5f001596b346',
    '65fc9b024c3e5f001596b33e',
    '65fc9ac24c3e5f001596b339',
    '65fc9a6c4c3e5f001596b333',
    //Dev Brackets 
    '66d954e217d8ccc0ec76fc2f', // Bracket L
    '66d9ae9517d8ccc0ec76fc34', // Bracket C
    '66d9af5217d8ccc0ec76fc35' // Bracket Z
  ];
} else {
  mezcoModelIds = [
    '6626c5aa5756571fc16015ec',
    '66275b535756571fc16015f0',
    '66276ff45756571fc16015f3',
    '662797135756571fc16015f9',
    '6627987b5756571fc16015fd',
    //Dev Brackets 
    '66d954e217d8ccc0ec76fc2f', // Bracket L
    '66d9ae9517d8ccc0ec76fc34', // Bracket C
    '66d9af5217d8ccc0ec76fc35' // Bracket Z
  ];
}
const drawerRoutes = [
  {
    title: `Home`,
    route: allPaths.ADMIN,
    icon: <HomeOutlined />
  }
];

if (process.env.REACT_APP_ENVIRONMENT === 'production') {
  Chromatic3DProds = [
    '6446b009c5b63632f3c1cb15',
    '6446b009c5b63632f3c1cb18',
    '6446b009c5b63632f3c1cb1b',
    '6446b009c5b63632f3c1cb1e',
    '6446b009c5b63632f3c1cb21',
    '6446b009c5b63632f3c1cb24'
  ];
} else {
  Chromatic3DProds = [
    '64424b7dca971f39d4e31e44',
    '64424b7dca971f39d4e31e47',
    '64424b7dca971f39d4e31e4a',
    '64424b7dca971f39d4e31e4d',
    '64424b7dca971f39d4e31e50',
    '64424b7dca971f39d4e31e53'
  ];
}

const adminTypes = {
  sub: 'SUB',
  super: 'SUPER'
};

const EARNESTMAXVALUESDEPS = {
  ThruDiameter: 'Diameter',
  WireDiameter: 'Diameter',
  PinDiameter: 'Diameter',
  DogPointDiameter: 'Diameter',
  DogPointLength: 'Length',
  Partial_thread_length: 'Length'
};

const EARNESTCHECKDEPS = {
  ThruDiameter: 'Post_Processing__Top_hole',
  WireDiameter: 'Post_Processing__Side_hole',
  PinDiameter: 'Pin_Lock_Hole',
  PinOffset: 'Pin_Lock_Hole',
  DogPointDiameter: 'Dog_Point',
  DogPointLength: 'Dog_Point',
  PartialThread: 'List_QAoAroAoJ5JICg'
};


export {
  bgColor,
  storLocModalId,
  albaRacingModelId,
  mezcoModelIds,
  drawerRoutes,
  allPaths,
  Chromatic3DProds,
  adminTypes,
  global,
  EARNESTMAXVALUESDEPS,
  EARNESTCHECKDEPS,
  LIBERTY_PEN_GUN,
  ErnestModelIds,
  SkyHookModelID
};
