// This Component is only for the Stor-Loc product in frontStore and it's model id is 63b4cc4f65be5d2b79774084(CONSTANT storLocModalId)
import React, { Fragment, useEffect, useState } from 'react';

//used a package
import Select from 'react-select';

// Material UI imports
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import { Box, Divider, IconButton, Input, List, ListItem, Slider, Tooltip } from '@mui/material';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { columnsWidthOptions, depthOptions, drawerHeightOptions, risersOptions, topOptions, drawerLayoutOptions } from './StorLocConfig';
import LayoutDialog from './LayoutDialog';
import Info from '@mui/icons-material/Info';

const StorLoc = (props) => {
  const { configParam, defaultValue, setValue, setTempData, tempData, StorLocNewSchema } = props;
  // console.log("StorLoc ~ tempData:", tempData)

  const minFrameHeight = 500;
  const maxFrameHeight = 1440;
  const stepFrameHeight = 20;

  const [closeColumn, setCloseColumn] = useState(true);
  const [closeDrawer, setCloseDrawer] = useState(true);
  const [frameHeight, setFrameHeight] = useState(0);
  const [open, setOpen] = useState(false);
  const [selectLayoutForDrawer, setSelectLayoutForDrawer] = useState(null);
  const [prevRiserVal, setPrevRiserVal] = useState(null);

  useEffect(() => {
    setValue(configParam.message.parameterId, JSON.stringify(tempData));
    setFrameHeight(tempData?.Height || 0);
    if (prevRiserVal == null && Object.keys(tempData).length > 0) {
      setPrevRiserVal(StorLocNewSchema ? tempData?.RiserHeight : tempData?.Risers?.RiserHeight);
    }
  }, [tempData, StorLocNewSchema]);

  useEffect(() => {
    setTempData(defaultValue);
  }, []);

  const handleClickOpen = (drawerNo) => {
    setSelectLayoutForDrawer(drawerNo);
    setOpen(true);
  };
  const handleClose = () => {
    setSelectLayoutForDrawer(null);
    setOpen(false);
  };
  const addColumns = () => {
    let tempJson = { ...tempData };
    if (StorLocNewSchema) {
      tempJson.Columns?.push(columnsWidthOptions.find((opt) => opt?.isDefault === true)?.id);
    } else {
      tempJson.Columns?.push({ width: { width: columnsWidthOptions.find((opt) => opt?.isDefault === true)?.value } });
    }
    setTempData(tempJson);
  };
  const addDrawers = () => {
    let tempJson = { ...tempData };
    if (StorLocNewSchema) {
      tempJson.Drawers?.push({
        h: drawerHeightOptions.find((opt) => opt?.isDefault === true)?.id,
        l: 1
      });
    } else {
      tempJson.Drawers?.push({
        arrayPointIndex: tempJson?.Drawers?.length + 1,
        double: false,
        height: { height: drawerHeightOptions.find((opt) => opt?.isDefault === true)?.value },
        layout: { Layout: 'Layout 1' }
      });
    }
    setTempData(tempJson);
  };
  const removeColumn = (i) => {
    setCloseColumn(true);
    let tempJson = { ...tempData };
    tempJson.Columns = tempJson?.Columns?.filter((v, ind) => ind != i);
    setTempData(tempJson);
    setTimeout(() => {
      setCloseColumn(false);
    }, 500);
  };
  const removeDrawer = (i) => {
    setCloseDrawer(true);
    let tempJson = { ...tempData };
    if (StorLocNewSchema) {
      tempJson.Drawers = tempJson?.Drawers?.filter((v, ind) => ind != i);
    } else {
      tempJson.Drawers = tempJson?.Drawers?.filter((v, ind) => ind != i);
    }
    setTempData(tempJson);
    setTimeout(() => {
      setCloseDrawer(false);
    }, 500);
  };
  const changeDepth = (value) => {
    let tempJson = { ...tempData };
    StorLocNewSchema ? tempJson.Depth = value : tempJson.Depth.depth = value;
    setTempData(tempJson);
  };
  const changeRisers = (selectedOption) => {
    let tempJson = { ...tempData };
    if (StorLocNewSchema) {
      tempJson.RiserHeight = selectedOption.inchValue;
      setPrevRiserVal(selectedOption.inchValue);
    } else {
      tempJson.Risers.RiserHeight = value;
      setPrevRiserVal(selectedOption.value);
    }
    setTempData(tempJson);
  };
  const changeTop = (value) => {
    let tempJson = { ...tempData };
    if (!StorLocNewSchema) {
      tempJson.Top.Top = value.value;
      if (tempJson?.Top?.Top === 'None' || tempJson?.Top?.Top === 'Stainless Steel Capped') {
        tempJson.Risers.RiserHeight = 'None';
      }
      if (tempJson?.Top?.Top === 'Stainless Steel Capped Maple' || tempJson?.Top?.Top === 'Maple') {
        tempJson.Risers.RiserHeight = prevRiserVal;
      }
    } else {
      tempJson.Top = value.id;
      if (tempJson?.Top === "None" || tempJson?.Top === 'Stainless') {
        tempJson.RiserHeight = 0;
      }
      if (tempJson?.Top === 'Capped' || tempJson?.Top === 'Maple') {
        tempJson.RiserHeight = prevRiserVal;
      }
    }
    setTempData(tempJson);
  };
  const changeHeight = (value) => {
    console.log("changeHeight ~ value:", value)
    let tempJson = { ...tempData };
    let frameHeight = Number(value) || 0;
    if (value > 1440) {
      frameHeight = 1440;
    }

    const diffFromMin = frameHeight - minFrameHeight;
    const modulesOFDiff = diffFromMin % stepFrameHeight;
    const midValOfStep = stepFrameHeight / 2;
    if (value > minFrameHeight) {
      if (modulesOFDiff < midValOfStep) {
        frameHeight = frameHeight - modulesOFDiff;
      } else {
        frameHeight = frameHeight + (stepFrameHeight - modulesOFDiff);
      }
    }
    tempJson.Height = frameHeight;
    setFrameHeight(frameHeight);
    setTempData(tempJson);
  };
  const changeDrawerHeight = (selectedOption, i) => {
    let tempJson = { ...tempData };
    if (StorLocNewSchema) {
      tempJson.Drawers[i].h = selectedOption.id;
    } else {
      tempJson.Drawers[i].height.height = selectedOption.value;
    }
    setTempData(tempJson);
  };
  const changeDrawerLayout = (value, i) => {
    let tempJson = { ...tempData };
    if (StorLocNewSchema) {
      tempJson.Drawers[i].l = value;
    } else {
      tempJson.Drawers[i].layout.Layout = value;
    }
    setTempData(tempJson);
    handleClose();
  };
  const changeDrawerDouble = (value, i) => {
    let tempJson = { ...tempData };
    if (StorLocNewSchema) {
      tempJson.Drawers[i].d = value;
    } else {
      tempJson.Drawers[i].double = value;
    }
    setTempData(tempJson);
  };

  const changeWheels = (value) => {
    let tempJson = { ...tempData };
    tempJson.Wheels = value;
    setTempData(tempJson);
  };
  const changeColumn = (selectedOption, i) => {
    let tempJson = { ...tempData };
    if (StorLocNewSchema) {
      tempJson.Columns[i] = selectedOption.id;
    } else {
      tempJson.Columns[i].width.width = selectedOption.value;
    }
    setTempData(tempJson);
  };
  const marks = [
    {
      value: minFrameHeight,
      label: minFrameHeight.toString()
    },
    {
      value: maxFrameHeight,
      label: maxFrameHeight.toString()
    }
  ];

  return (
    <Fragment>
      <Grid item xs={12}>
        {/* for Depth controller for the Stor-Loc Model */}
        <Grid container={true} item xs={12} mt={1}>
          <label style={{ marginBottom: 5 }} className="configParamLabel">
            Depth{' '}
          </label>
          <Select
            className="configurationControl"
            isClearable={false}
            isSearchable={true}
            options={depthOptions}
            value={depthOptions?.find((c) => {
              return StorLocNewSchema ? c?.id == tempData?.Depth : c?.value == tempData?.Depth?.depth
            })}
            onChange={(selectedOption) => {
              changeDepth(StorLocNewSchema ? selectedOption?.id : selectedOption?.value);
            }}
            menuPortalTarget={document.querySelector('body')}
            styles={{
              menu: (provided) => ({
                ...provided,
                marginBottom: 0,
                marginTop: 0
              }),
              menuList: (provided) => ({
                ...provided,
                paddingTop: 0,
                paddingBottom: 0
              })
            }}
          />
        </Grid>

        {/* for frame height controller for the Stor-Loc Model */}
        <Grid container={true} item xs={12} mt={1}>
          <label style={{ marginBottom: 5 }} className="configParamLabel">
            Housing Height (mm)
          </label>
          <Grid container={true} className="configurationControl">
            <Grid item xs alignItems="center" mt={0} ml={2}>
              <Slider
                id={`range-frameHeight`}
                size="small"
                onChange={(_, value) => changeHeight(value)}
                max={maxFrameHeight}
                min={minFrameHeight}
                step={stepFrameHeight}
                marks={marks}
                value={frameHeight}
              />
            </Grid>
            <Grid item alignItems="center" mt={2} ml={3}>
              <Input
                id={`number-frameHeight`}
                size="small"
                value={frameHeight}
                onChange={(e) => changeHeight(e?.target?.value)}
                inputProps={{
                  step: 20,
                  min: minFrameHeight,
                  max: maxFrameHeight,
                  type: 'number'
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        {/* for Top controller for the Stor-Loc Model  */}
        <Grid container={true} item xs={12} mt={1}>
          <label style={{ marginBottom: 5 }} className="configParamLabel">
            Top
            <Tooltip
              title={
                <>
                  <List>
                    <ListItem>
                      Our largest single piece of maple is 144&#34; wide. &ldquo;Stainless Steel Capped Maple&rdquo; or &ldquo;Stainless
                      Steel Capped&rdquo; is recommended for anything above this width for aesthetic purposes. Our stainless tops can be as
                      wide as 168&#34;
                    </ListItem>
                  </List>
                </>
              }
            >
              <IconButton sx={{ py: 0, fontSize: '16px', alignItems: 'flex-start' }}>
                <Info sx={{ fontSize: 'inherit', lineHeight: 'inherit' }} />
              </IconButton>
            </Tooltip>
          </label>
          <Select
            className="configurationControl"
            isClearable={false}
            isSearchable={true}
            options={topOptions}
            value={topOptions?.find((c) => (StorLocNewSchema ? c?.id == tempData?.Top : c?.label == tempData?.Top?.Top))}
            onChange={(selectedOption) => {
              changeTop(selectedOption);
            }}
            menuPortalTarget={document.querySelector('body')}
            styles={{
              menu: (provided) => ({
                ...provided,
                marginBottom: 0,
                marginTop: 0
              }),
              menuList: (provided) => ({
                ...provided,
                paddingTop: 0,
                paddingBottom: 0
              })
            }}
          />
        </Grid>

        <Grid container={true} item xs={12} mt={1}>
          <label style={{ marginBottom: 5, display: 'flex' }} className="configParamLabel">
            Riser{' '}
            <Tooltip
              title={
                <>
                  <List>
                    <ListItem>Only available with Maple and Stainless Steel Capped Maple tops</ListItem>
                  </List>
                </>
              }
            >
              <IconButton sx={{ py: 0, fontSize: '16px', alignItems: 'flex-start' }}>
                <Info sx={{ fontSize: 'inherit', lineHeight: 'inherit' }} />
              </IconButton>
            </Tooltip>
          </label>
          <Select
            isDisabled={StorLocNewSchema ? tempData?.Top === 'None' || tempData?.Top === 'Stainless' ? true : false : tempData?.Top?.Top === 'None' || tempData?.Top?.Top === 'Stainless Steel Capped' ? true : false}
            className="configurationControl"
            isClearable={false}
            isSearchable={true}
            options={risersOptions}
            value={
              StorLocNewSchema ?
                (
                  tempData?.Top === 'None' || tempData?.Top?.Top === 'Stainless'
                    ? risersOptions?.find((c) => c?.inchValue == 0)
                    : risersOptions?.find((c) => c?.inchValue == tempData?.RiserHeight)
                ) : (
                  tempData?.Top?.Top === 'None' || tempData?.Top?.Top === 'Stainless Steel Capped'
                    ? risersOptions?.find((c) => c?.value == 'None')
                    : risersOptions?.find((c) => c?.value == tempData?.Risers?.RiserHeight)
                )
            }
            onChange={(selectedOption) => {
              changeRisers(selectedOption);
            }}
            menuPortalTarget={document.querySelector('body')}
            styles={{
              menu: (provided) => ({
                ...provided,
                marginBottom: 0,
                marginTop: 0
              }),
              menuList: (provided) => ({
                ...provided,
                paddingTop: 0,
                paddingBottom: 0
              })
            }}
          />
        </Grid>

        {/* for wheels checkbox controller for the Stor-Loc Model */}
        <Grid container={true} item xs={12} mt={1}>
          <label className="configParamLabel" style={{ display: 'block', width: '75%' }}>
            Heavy Duty 6&#34; Casters(add 7.5&#34; to total height, 1000 lb per caster, with aluminum handles)
          </label>

          {tempData?.Height ? (
            <Grid item xs={3}>
              <Checkbox
                size="small"
                checked={tempData?.Wheels == true}
                value={tempData?.Wheels == true}
                onChange={(e) => {
                  changeWheels(e?.target?.checked);
                }}
              />
              <span>Wheels</span>
            </Grid>
          ) : null}
        </Grid>

        {/* for Colums controller for the Stor-Loc Model */}
        <Grid container={true} item xs={12} mt={1}>
          <label style={{ marginBottom: 5, display: 'flex' }} className="configParamLabel">
            {closeColumn ? (
              <ArrowRightIcon onClick={() => setCloseColumn(false)} className="rotateArrow" />
            ) : (
              <ArrowDropDownIcon onClick={() => setCloseColumn(true)} className="rotateArrow" />
            )}
            Columns{' '}
            <Tooltip
              title={
                <>
                  <List>
                    <ListItem>
                      Cabinets wider than 120&#34; are welded together into one unibody housing. It is recommended to to use a top for these
                      units for aesthetic purposes.
                    </ListItem>
                  </List>
                </>
              }
            >
              <IconButton sx={{ py: 0, fontSize: '16px', alignItems: 'flex-start' }}>
                <Info sx={{ fontSize: 'inherit', lineHeight: 'inherit' }} />
              </IconButton>
            </Tooltip>
          </label>

          <Box sx={{ width: '100%' }}>
            {!closeColumn ? (
              <>
                {tempData?.Columns?.map((x, i) => {
                  return (
                    <Fragment key={`configParam-${i}`}>
                      <div className="row_div">
                        <label style={{ marginBottom: 5 }} className="configParamLabel">
                          Column
                        </label>
                        <IconButton
                          disableFocusRipple
                          disableRipple
                          disableTouchRipple
                          sx={{
                            fontSize: '0.98rem',
                            border: '1px solid red',
                            borderRadius: '4px',
                            paddingY: 0,
                            color: 'red',
                            paddingX: '3px'
                          }}
                          onClick={() => removeColumn(i)}
                        >
                          Delete
                          <DeleteOutlineIcon sx={{ color: 'red', height: '1.4em', width: '1.4em' }} className="closeOption" fontSize="1" />
                        </IconButton>
                      </div>
                      <Select
                        isClearable={false}
                        isSearchable={true}
                        options={columnsWidthOptions}
                        defaultValue={columnsWidthOptions?.find((c) => {
                          return StorLocNewSchema ? c?.id === tempData?.Columns[i] : c?.value === tempData?.Columns[i]?.width?.width;
                        })}
                        onChange={(selectedOption) => {
                          changeColumn(selectedOption, i);
                        }}
                        menuPortalTarget={document.querySelector('body')}
                        styles={{
                          menu: (provided) => ({
                            ...provided,
                            marginBottom: 0,
                            marginTop: 0
                          }),
                          menuList: (provided) => ({
                            ...provided,
                            paddingTop: 0,
                            paddingBottom: 0
                          })
                        }}
                      />
                    </Fragment>
                  );
                })}
                <br />
                <Button sx={{ width: '100%' }} variant="outlined" onClick={() => addColumns()}>
                  Add column
                </Button>
                <br />
              </>
            ) : null}
          </Box>
        </Grid>

        {/* for Drawer Stack controller for the Stor-Loc Model */}
        <Grid container={true} item xs={12} mt={1}>
          <label style={{ marginBottom: 5, display: 'flex', width: '100%' }} className="configParamLabel">
            {closeDrawer ? (
              <ArrowRightIcon onClick={() => setCloseDrawer(false)} className="rotateArrow" />
            ) : (
              <ArrowDropDownIcon onClick={() => setCloseDrawer(true)} className="rotateArrow" />
            )}
            Drawer Stack{' '}
            <Tooltip
              title={
                <>
                  <List>
                    <ListItem>
                      If a drawer dimension is changed, the selected layout will repeat to fill the rest of the drawer, or be cut short to
                      fit.
                    </ListItem>
                    <ListItem>
                      Narrow Wide and Standard Wide can be combined with themselves and each other to become Double Narrow Wide, Semi Wide,
                      and Double Wide by clicking &ldquo;Double Width&rdquo; on a selected Drawer
                    </ListItem>
                    <ListItem>
                      &ldquo;Double Width&rdquo; drawers can be placed above or below the drawers that make them up. Combine two Narrow
                      Wides to get Double Narrow Wide 45 3/4&#34; (41 1/2&#34; usable)
                    </ListItem>
                    <ListItem>
                      Combine one Narrow Wide with one Standard Wide in any orientation to get Semi Wide 52&#34; (47 1/2&#34; usable)
                    </ListItem>
                    <ListItem>Combine two Standard Wides to get Double Wide 58 1/4&#34; (53 1/2&#34; usable)</ListItem>
                  </List>
                </>
              }
            >
              <IconButton sx={{ py: 0, fontSize: '16px', alignItems: 'flex-start' }}>
                <Info sx={{ fontSize: 'inherit', lineHeight: 'inherit' }} />
              </IconButton>
            </Tooltip>
          </label>
          {!closeDrawer ? (
            <>
              {tempData?.Drawers?.map((x, i) => {
                return (
                  <Box key={`configParam-${i}`} sx={{ width: '100%' }}>
                    <div className="row_div ">
                      <label style={{ marginBottom: 5 }} className="configParamLabel">
                        Drawer ({i + 1})
                      </label>
                      <IconButton
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                        sx={{
                          fontSize: '0.98rem',
                          border: '1px solid red',
                          borderRadius: '4px',
                          paddingY: 0,
                          color: 'red',
                          paddingX: '3px'
                        }}
                        onClick={() => removeDrawer(i)}
                      >
                        Delete
                        <DeleteOutlineIcon sx={{ color: 'red', height: '1.4em', width: '1.4em' }} className="closeOption" fontSize="1" />
                      </IconButton>
                    </div>
                    <label style={{ marginBottom: 5, opacity: 0.6 }} className="configParamLabel">
                      Height
                    </label>
                    <Select
                      isClearable={false}
                      isSearchable={true}
                      options={drawerHeightOptions}
                      defaultValue={() => {
                        if (StorLocNewSchema) {
                          return drawerHeightOptions?.find((c) => c?.id === tempData?.Drawers[i]?.h)
                        } else {
                          return drawerHeightOptions?.find((c) => c?.value === tempData?.Drawers[i]?.height?.height)
                        }
                      }}
                      onChange={(selectedOption) => {
                        changeDrawerHeight(selectedOption, i);
                      }}
                      menuPortalTarget={document.querySelector('body')}
                      styles={{
                        menu: (provided) => ({
                          ...provided,
                          marginBottom: 0,
                          marginTop: 0
                        }),
                        menuList: (provided) => ({
                          ...provided,
                          paddingTop: 0,
                          paddingBottom: 0
                        })
                      }}
                    />
                    <label style={{ marginBottom: 5, opacity: 0.6 }} className="configParamLabel">
                      Layout
                    </label>
                    <Button
                      sx={{ width: '100%' }}
                      variant="outlined"
                      onClick={() => handleClickOpen(i + 1)}
                    >{`Select - ${StorLocNewSchema ? drawerLayoutOptions.find((item) => item.id == tempData?.Drawers[i]?.l)?.label : tempData?.Drawers[i]?.layout?.Layout}`}</Button>
                    <Checkbox
                      checked={StorLocNewSchema ? tempData?.Drawers[i]?.d : tempData?.Drawers[i]?.double}
                      value={StorLocNewSchema ? tempData?.Drawers[i]?.d : tempData?.Drawers[i]?.double}
                      onChange={(e) => {
                        changeDrawerDouble(e?.target?.checked, i);
                      }}
                    />
                    <span>Double Width</span>
                    <br />
                    <Divider variant="fullWidth" />
                    <br />
                  </Box>
                );
              })}
              <br />
              <Button sx={{ width: '100%' }} variant="outlined" onClick={() => addDrawers()}>
                Add Drawer
              </Button>
            </>
          ) : null}
        </Grid>
      </Grid>
      <LayoutDialog
        open={open}
        handleClose={handleClose}
        selectedDrawer
        selectLayoutForDrawer={selectLayoutForDrawer}
        changeDrawerLayout={(layout, drawerNo) => changeDrawerLayout(layout, drawerNo)}
        StorLocNewSchema={StorLocNewSchema}
      />
    </Fragment>
  );
};

export default StorLoc;
