import React, { useState, useEffect } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, TextField, Typography, CircularProgress, Box } from '@mui/material';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import axios from 'axios';

import { useSnackbar } from 'notistack';
import { errorMessage } from '../../utils/helpers';
import { POST } from '../../utils/apis';
import { ErnestModelIds } from '../../utils/constants';

// Define validation schema using Yup
const schema = yup.object().shape({
    name: yup.string().required('Name is required'),
    email: yup.string().email('Invalid email').required('Email is required'),
    notes: yup.string(),
});

const DownloadCADFileDialog = ({ downloadCADFile, productPrice, isPreview, modelRefreshing, modelId, configration }) => {
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [isSubmitting, setIsSubmitting] = useState(false);

    const { enqueueSnackbar } = useSnackbar();

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(schema),
    });

    const openDialog = () => setIsDialogOpen(true);
    const closeDialog = () => setIsDialogOpen(false);

    useEffect(() => {
        // console.log("isSubmitting changed:", isSubmitting);
    }, [isSubmitting]);

    const onSubmit = async (formData) => {
        setIsSubmitting(true);
        const data = {
            ...formData,
            modelId,
            configration,
            isFrom: "spokbee"
        }

        try {
            const responce = await axios.post(POST.SAVE_USER_DATA, data);

            await downloadCADFile();
            setIsSubmitting(false);
            closeDialog()

        } catch (err) {
            console.error('Error saving form data:', err);
            enqueueSnackbar(err?.message || errorMessage(), { variant: 'error' });
        }
    };

    return (
        <>

            <Button
                variant="contained"
                className="Button mt-2"
                onClick={ErnestModelIds.includes(modelId) ? downloadCADFile : openDialog}
                disabled={isPreview || modelRefreshing || productPrice === ''}
            >
                Download CAD File
            </Button>


            <Dialog open={isDialogOpen} onClose={closeDialog}>
                <DialogTitle
                    sx={{
                        color: 'black',
                        fontWeight: 'bold',
                        fontSize: '20px',
                        padding: '16px'
                    }}>
                    Enter Your Details
                </DialogTitle>
                <DialogContent dividers>
                    {isSubmitting && (
                        <Box
                            sx={{
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                flexDirection: 'column',
                                justifyContent: 'center',
                                alignItems: 'center',
                                backgroundColor: 'rgba(192,192,192,0.5)',
                                zIndex: 1
                            }}
                        >
                            <CircularProgress />
                            <Typography variant="body1" sx={{ marginTop: 2, textAlign: 'center', fontWeight: 'bold' }}>
                                Downloading CAD file...<br />
                                This may take a few moments
                            </Typography>
                        </Box>
                    )}
                    <form>
                        <TextField
                            label="Name"
                            fullWidth
                            margin="normal"
                            {...register('name')}
                            error={!!errors.name}
                            helperText={errors.name?.message}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    margin: 0,
                                    padding: 0
                                }
                            }}
                        />
                        <TextField
                            label="Email"
                            fullWidth
                            margin="normal"
                            type="email"
                            {...register('email')}
                            error={!!errors.email}
                            helperText={errors.email?.message}
                            sx={{
                                '& .MuiInputLabel-root': {
                                    margin: 0,
                                    padding: 0
                                }
                            }}
                        />
                        <TextField
                            label="Notes"
                            fullWidth
                            margin="normal"
                            multiline
                            rows={3}
                            {...register('notes')}
                        />
                    </form>
                </DialogContent>
                <DialogActions sx={{
                    padding: '16px'
                }}>
                    <Button onClick={closeDialog} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit(onSubmit)} variant="contained" color="primary" disabled={isSubmitting}>
                        Submit & Download CAD
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default DownloadCADFileDialog;
