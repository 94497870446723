const authApi = `/api/user`;
const getApi = `/api/get`;
const postApi = `/api/post`;
const adminApi = `/api/admin`;
const userApi = `/api/user`;
const SUBSCRIPTIONPLANS = `/api/subscription-plans/`;
const MATERIALS = `/api/materials`;
const MANUFACTURERS = `/api/manufacturers`;
const CATEGORIES = `/api/categories`;
const PRODUCTS_BASE = `/api/products`;
const NOTIFICATIONS_BASE = `/api/notifications`;
const ORDERS_BASE = `/api/orders`;
const FEEDBACK_BASE = `/api/feedback`;
const SELLER_BASE = `/api/sellers`;

const AUTH = {
  LOGIN: `${authApi}/login`,
  ONSHAPE_LOGIN: `${authApi}/oauthLogin`,
  //ADMINLOGIN: `${adminApi}/login`,
  SIGNUP: `${authApi}/signup`,
  ONSHAPE_OAUTH_GENERATE_LOGIN: `${authApi}/onshape/generate_login`,
  ONSHAPE_OAUTH_LOGIN: `${authApi}/onshape/login`,
  ONSHAPE_USER_DETAILS: `${authApi}/onshape/get-user-details`
};

const GET = {
  EDITOR_BY_ID: `${getApi}/editor`,
  EDITOR_PREVIEW_BY_ID: `${getApi}/editor/preview`,
  MATERIAL_DATA: `${getApi}/materials`,
  GET_PRODUCTS: `${getApi}/products`,
  IMAGES: `${getApi}/images`,
  GET_ALL_MODELS: `${getApi}/all-models`,
  MODEL_PARTS: `${getApi}/parts`,
  // GET_MODEL_CATEGORIES: `${getApi}/models/categories`,
  GET_COMPANY_PRODUCTS: `${getApi}/company-products`,
  GET_PRODUCTS_IMAGES: `${getApi}/product-media`, // params ID
  SELLER_DASHBOARD: `${getApi}/seller/dashboard`
};

const POST = {
  MODEL_CONFIGURATION: `${postApi}/configuration`,
  UPDATE_MATERIALS: `${postApi}/materials`,
  MODEL_PARTS: `${postApi}/parts`,
  BOUNDING_BOX: `${postApi}/bounding-box`,
  ADD_PRODUCT: `${postApi}/product`,
  SAVE_CONFIGURATION: `${postApi}/store-model-configuration`,
  SUBSCRIBE_NEWSLETTER: `${postApi}/newsletter`,
  GET_ALL_MODELS: `${postApi}/all-models`,
  CONTACT_US: `${postApi}/contact-us`,
  PDF_GENERATE: `${postApi}/storloc/pdf`,
  DOWNLOAD_CAD: `${postApi}/download-cad`,
  SAVE_USER_DATA: `${postApi}/cad-entity`
};

const ADMIN = {
  GET: {
    ALL_MODELS: `${adminApi}/all-models`,
    // ALL_MATERIALS: `${adminApi}/all-materials`,
    DELETE_MODEL: `${adminApi}/delete-model`,
    VERIFY_ADMIN: `${adminApi}/verify-admin`,
    GET_ALL_ADMINS: `${adminApi}/get-all-Admins`
  },
  POST: {
    ADD_MODEL: `${adminApi}/add-model`,
    UPDATE_MODEL: `${adminApi}/update-model`,
    LOGIN: `${adminApi}/login`,
    CHANGE_ADMIN_PASSWORD: `${adminApi}/change-admin-password`,
    CHANGE_ADMIN_USERNAME: `${adminApi}/change-admin-user-name`,
    DELETE_ADMIN: `${adminApi}/delete-Admins`,
    ADD_SUB_ADMIN: `${adminApi}/add-sub-Admin`
  }
};

const USER = {
  POST: {
    LOGIN: `${userApi}/post/login`,
    REGISTER: `${userApi}/post/register`,
    FORGOT_PASSWORD: `${userApi}/post/forgot-password`,
    RESET_PASSWORD: `${userApi}/post/reset-password`,
    MY_PROFILE: `${userApi}/post/update-profile`,
    CHANGE_PASSWORD: `${userApi}/post/update-password`,
    SELLER_REGISTER: `${userApi}/post/seller/register`,
    UPGRADE_SUBSCRIPTION: `${userApi}/post/seller/upgrade-subscription`,
    VALIDATE_INVITE: `${userApi}/post/validate-invitation-token`,
    SET_NEW_PASSWORD: `${userApi}/post/set-new-password`
  }
};

const PRODUCTS = {
  ADD: PRODUCTS_BASE,
  EDIT: PRODUCTS_BASE,
  UPDATE: PRODUCTS_BASE,
  DELETE: PRODUCTS_BASE,
  GET_ALL: `${PRODUCTS_BASE}/get-all`,
  GET_BY_DOCUMENT: `${PRODUCTS_BASE}/get-by-doc`,
  GET_BY_ID: `${PRODUCTS_BASE}`,
  GET_CONFIGURATION: `${PRODUCTS_BASE}/configuration`
};

const NOTIFICAIONS = {
  ADD: NOTIFICATIONS_BASE,
  READ: `${NOTIFICATIONS_BASE}/read`,
  GET_ALL: `${NOTIFICATIONS_BASE}`
};

const ORDERS = {
  GET_ALL: `${ORDERS_BASE}/get-all`
};

const FEEDBACK = {
  SEND: FEEDBACK_BASE
};

const SELLER = {
  GETSTOREFRONTS: `${SELLER_BASE}/my-storefronts`,
  STOREFRONTUSERSBASE: `${SELLER_BASE}/storefront-users`
};

export {
  AUTH,
  GET,
  POST,
  ADMIN,
  USER,
  SUBSCRIPTIONPLANS,
  MATERIALS,
  CATEGORIES,
  PRODUCTS,
  ORDERS,
  NOTIFICAIONS,
  FEEDBACK,
  MANUFACTURERS,
  SELLER
};
